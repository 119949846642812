import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/nextjs/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["RootBox","MainBox"] */ "/nextjs/src/app/(unauthorized)/styles.ts");
;
import(/* webpackMode: "eager" */ "/nextjs/src/entities/auth/components/auth-guard/index.tsx");
;
import(/* webpackMode: "eager" */ "/nextjs/src/entities/auth/components/login/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContext"] */ "/nextjs/src/entities/auth/providers/auth/context.ts");
;
import(/* webpackMode: "eager" */ "/nextjs/src/entities/auth/providers/index.tsx");
