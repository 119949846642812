'use client';

import { Box, styled } from '@mui/material';

export const RootBox = styled(Box)(() => ({
	display: 'flex',
	height: '100%',
	width: '100%',
}));

export const MainBox = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1, 4, 0),
	flexGrow: 1,
})) as typeof Box;
